<template>
  <div>
    <br />
    <div class="row row-sm">
      <div class="col-12 col-md-6 col-lg-3 col-xl-3">
        <label for="">
          {{ $t("sale7") }}
          <span class="text-danger">*</span>
        </label>
        <select
          v-model="filter.customer_reference"
          class="form-select"
          required
        >
          <option selected value="--">--</option>
          <option
            v-for="(customer, index) in customers"
            :key="index++"
            :value="customer.reference"
            :style="
              customer.blocked || customer.fullName.includes('Bloqué')
                ? { color: 'red' }
                : ''
            "
          >
            {{ customer.fullName }} {{ customer.society }}
          </option>
        </select>
      </div>

      <div class="col-12 col-md-6 col-lg col-xl">
        <label for="">
          {{ $t("sale8") }} <span class="text-danger">*</span>
        </label>
        <input v-model="filter.startDate" type="date" class="form-control" />
      </div>
      <div class="col-12 col-md-6 col-lg col-xl">
        <label for="">
          {{ $t("sale9") }} <span class="text-danger">*</span>
        </label>
        <input v-model="filter.endDate" type="date" class="form-control" />
      </div>

      <div class="col-12 col-md-6 col-lg-2 col-xl-2">
        <label for=""> {{ $t("sale10") }} </label>
        <select v-model="filter.paymentStatus" class="form-select">
          <option value="--">--</option>
          <option value="Payé">Payé</option>
          <option value="Impayé">Impayé</option>
        </select>
      </div>
    </div>
    <br />
    <div class="row justify-content-end">
      <div class="col-12 col-md-6 col-lg-auto col-xl-auto">
        <button @click="toFilter(filter)" class="btn btn-secondary">
          <i class="bi bi-funnel"></i>
          {{ $t("sale11") }}
        </button>
      </div>

      <div class="col-12 col-md-6 col-lg-auto col-xl-auto">
        <button @click="refresh()" class="btn btn-secondary">
          <i class="bi bi-arrow-clockwise"></i>
          {{ $t("sale12") }}
        </button>
      </div>

      <div class="col-12 col-md-6 col-lg-auto col-xl-auto">
        <button @click="printMany(selections)" class="btn btn-secondary">
          <i class="bi bi-printer"></i>
          Imprimer BL
        </button>
      </div>

      <div class="col-12 col-md-6 col-lg-auto col-xl-auto">
        <button
          @click="$router.push({ name: 'sales-items-create' })"
          class="btn btn-primary"
        >
          <i class="bi bi-person-plus"></i>
          {{ $t("sale13") }}
        </button>
      </div>

    </div>
    <br />
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">
            {{ $t("sale14") }}
          </th>
          <th scope="col">
            {{ $t("sale15") }}
          </th>
          <th scope="col">
            {{ $t("sale16") }}
          </th>
          <th scope="col" class="text-end">
            {{ $t("sale17") }}
          </th>
          <th scope="col" class="text-end">
            {{ $t("sale18") }}
          </th>
          <th scope="col" class="text-end">
            {{ $t("sale19") }}
          </th>
          <th scope="col" class="text-end">
            {{ $t("sale20") }}
          </th>
          <th scope="col" class="text-center">
            {{ $t("sale21") }}
          </th>
          <th scope="col"></th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(sale, index) in sales.filter((p) => p.validated == true)"
          :key="index++"
          :class="
            sale.totalPriceTTC < 0
              ? 'bg-warning-faint border-top border-bottom border-white'
              : sale.totalPriceTTC == sale.amountPaid
              ? 'bg-success-faint border-top border-bottom border-white'
              : ''
          "
        >
          <td>
            <input
              class="form-check-input"
              type="checkbox"
              :id="sale.reference"
              :value="sale.reference"
              v-model="selections"
            />
            {{ index }} 
          </td>
          <td>
            <label :for="sale.reference">
              {{ sale.reference }}
            </label>
          </td>
          <td>
            <span>
              {{ sale.customerFullName }}
            </span>
          </td>
          <td>{{ sale.date }}</td>
          <td class="text-end">
            <span v-if="sale.totalPriceTTC" class="text-primary">
              {{ sale.totalPriceTTC | toFixedWithSpace }}
            </span>
          </td>
          <td class="text-end">
            <span v-if="sale.totalQuantity >= 0" class="text-secondary">
              {{ sale.totalQuantity | toFixedWithSpace }}
            </span>
          </td>
          <td class="text-end">
            <span class="text-success">
              +
              {{ sale.amountPaid | toFixedWithSpace }}
            </span>
          </td>
          <td class="text-end">
            <span v-if="sale.amountUnpaid" class="text-danger">
              -
              {{ sale.amountUnpaid | toFixedWithSpace }}
            </span>
          </td>

          <td class="text-center">
            <span v-if="sale.userName">
              {{ sale.userName }}
            </span>
          </td>
          <td v-if="sale.validated">
            <span class="px-2 py-1 bg-success text-white rounded">
              {{ $t("sale22") }}
            </span>
          </td>

          <td v-if="!sale.validated">
            <span class="px-2 py-1 bg-warning rounded">
              {{ $t("sale23") }}
            </span>
          </td>
          <td  class="p-0 m-0">
            <button
              @click="
                $router.push({
                  name: 'sales-details',
                  params: { reference: sale.reference },
                })
              "
              class="btn p-0 m-0"
            >
              <i class="bi bi-info-square p-0 m-0"></i>
            </button>
          </td>

          
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td scope="col"></td>
          <td scope="col"></td>
          <td scope="col"></td>
          <td scope="col"></td>
          <td scope="col" class="text-end">
            <span class="bg-primary py-1 px-2 rounded text-white">
              {{ totalPriceTTC | toFixedWithSpace }}
            </span>
          </td>
          <td scope="col" class="text-end">
            <span class="bg-secondary py-1 px-2 rounded text-white">
              {{ totalQuantity | toFixedWithSpace }}
            </span>
          </td>
          <td scope="col" class="text-end">
            <span class="bg-success py-1 px-2 rounded text-white">
              {{ totalPaid | toFixedWithSpace }}
            </span>
          </td>
          <td scope="col" class="text-end">
            <span
              v-if="totalUnpaid"
              class="bg-danger py-1 px-2 rounded text-white"
            >
              {{ totalUnpaid | toFixedWithSpace }}
            </span>
          </td>
          <td scope="col"></td>
          <td scope="col"></td>
          <td scope="col"></td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import SaleDataTableVue from "../../components/cards/SaleDataTable.vue";
export default {
  // components: { SaleDataTableVue },
  data() {
    return {
      filter: {
        startDate: new Date(new Date().setFullYear(new Date().getFullYear()))
          .toLocaleString("en-CA")
          .split(",")[0],
        endDate: new Date(new Date().setFullYear(new Date().getFullYear()))
          .toLocaleString("en-CA")
          .split(",")[0],
      },
      selections: [],
    };
  },
  computed: { 
    ...mapGetters("sale", {
      sales: "getAll",
      totalQuantity: "getSalesTotalQuantity",
      totalPriceTTC: "getSalesTotalPriceTTC",
      totalPaid: "getSalesTotalPaid",
      totalUnpaid: "getSalesTotalUnpaid",
    }),
    ...mapGetters("customer", {
      customers: "getAll",
    }),
    ...mapGetters("auth", {
      userRole: "getUserRole",
    }),
  },
  created() {
    this.$store.dispatch("customer/getAll");
    this.$store.dispatch("sale/fetchSales");
  },
  methods: {
    async search(value) {
      return await this.$store.commit("sale/search", value);
    },
    async refresh() {
      await this.$store.dispatch("sale/fetchSales");
      await this.$store.dispatch("customer/getAll");
    },

    async toFilter(data) {
      await this.$store.dispatch("sale/filter", data);
    },
    async printMany(data) {
      await this.$store.dispatch("sale/printManyBill", data);
    },
  },
  filters: {
    toFixedWithSpace: (value) => {
      return parseFloat(value)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
    },
  },
};
</script>
